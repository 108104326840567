import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Common/Modal';
import ReactStars from 'react-rating-stars-component';
import { deviceWidth } from '@/utils/deviceWidth';
import styles from './index.module.scss';
import { publishReview } from '@/redux/actions';
import { toastNotifySuccess } from '../Common/Toast';
import { publishReviewToastNotificationText } from '../../utils/publishReviewToastNotificationText';
import {
  getImageUrlFromCompressedImages,
  imgSizeEnums,
} from '@/utils/compressedImagesHelpers';

const CustomerReviewModal = ({
  product,
  open,
  onClose,
  loggedInUserReview,
  onSubmit,
  orderStatusPage,
  order_id,
}) => {
  const dispatch = useDispatch();
  const { storeInfo, userData, reviewSettings } = useSelector((state) => ({
    storeInfo: state.storeReducer.store,
    userData: state.userReducer.data,
    reviewSettings: state.catalogReducer.reviewSettings,
  }));
  const [reviewState, setReviewState] = useState({
    full_name: loggedInUserReview?.full_name ? loggedInUserReview?.full_name : '',
    review: loggedInUserReview?.review ? loggedInUserReview?.review : '',
    rating: loggedInUserReview?.rating ? loggedInUserReview?.rating : 0,
  });
  const [DeviceWidth, setDeviceWidth] = useState(0);
  const imageUrl = product?.thumbnail_url || product?.image_url;
  const compressedImages = product?.images?.[0]?.compressed_images;

  useEffect(() => {
    setDeviceWidth(deviceWidth);
  }, [deviceWidth]);

  useEffect(() => {
    if (loggedInUserReview && !orderStatusPage) {
      setReviewState({
        full_name: loggedInUserReview?.full_name,
        review: loggedInUserReview?.review,
        rating: loggedInUserReview?.rating,
      });
    }
  }, [loggedInUserReview, orderStatusPage]);

  useEffect(() => {
    if (loggedInUserReview && open) {
      setReviewState({
        full_name: loggedInUserReview?.full_name,
        review: loggedInUserReview?.review,
        rating: loggedInUserReview?.rating,
      });
    }
  }, [open]);

  function handleRatingChange(rating) {
    setReviewState((prevState) => ({
      ...prevState,
      rating,
    }));
  }

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if ((inputValue && inputValue.trim()) || inputValue.length === 0) {
      const maxLength = 1500;
      setReviewState((prevState) => ({
        ...prevState,
        [e.target.name]: inputValue.slice(0, maxLength),
      }));
    }
  };

  const handleSubmit = () => {
    const payload = {
      store_id: storeInfo.store_id,
      order_id,
      review_rating: [
        {
          id: loggedInUserReview ? loggedInUserReview?.id : 0,
          item_id: product?.id || product?.item_id,
          full_name: reviewState.full_name,
          review: reviewState.review,
          status: reviewSettings.auto_publish ? 1 : 0,
          reply: loggedInUserReview ? loggedInUserReview?.reply : '',
          review_seen: false,
          phone: userData.phone,
          rating: reviewState.rating,
        },
      ],
    };
    dispatch(
      publishReview(payload, () => {
        toastNotifySuccess(
          publishReviewToastNotificationText(reviewSettings.auto_publish)
        );
        onSubmit && onSubmit();
      })
    );
    onClose();
  };

  const disableSubmitBtn = () =>
    !reviewState.review || !reviewState.full_name || !reviewState.rating;

  if (DeviceWidth <= 766)
    return (
      <Modal
        visible={open}
        customStyles={{
          height: '100%',
          width: '100%',
          borderRadius: 0,
          padding: 0,
        }}
        className="bottom-modal"
        onClose={onClose}
      >
        <div className={styles.mobileModal}>
          <div
            className={styles.header}
            style={{
              backgroundColor: storeInfo?.theme?.colors?.primary_color
                ? storeInfo?.theme?.colors?.primary_color
                : '#623abe',
            }}
          >
            <img
              className="pointer"
              src="/assets/images/close-icon.svg"
              width="14px"
              height="14px"
              onClick={onClose}
            />
            <p className="mv0 pl24px">Review Product</p>
          </div>
          <section>
            <div className="flex items-center pt30px">
              <img
                src={getImageUrlFromCompressedImages(compressedImages, imageUrl, {
                  maxImgSize: imgSizeEnums.LOW,
                })}
                width="54px"
                height="54px"
              />
              <div className="flex flex-column pl16px">
                <p className="mv0 f14px fw4">{product?.name || product?.item_name}</p>
                <p className="mv0 f14px pt4px fw4 star-dust">{product?.category?.name}</p>
              </div>
            </div>
            <div className="flex flex-column pt24px">
              <p className="mv0 pb8px">
                {reviewState.rating ? 'Your Rating' : 'Rate product'}
              </p>
              <div className="flex items-center">
                <ReactStars
                  key={`stars_${reviewState.rating}`}
                  size={27}
                  classNames={styles.gap}
                  onChange={handleRatingChange}
                  value={reviewState.rating}
                  count={5}
                />
                {loggedInUserReview?.rating && !loggedInUserReview?.review ? (
                  <p className="ml20px mv0 bg-lime-green-color pv4px ph8px f12px fw5 white-color br2 ">
                    Submitted
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex flex-column pt34px">
              <p className="mv0 pb10px fw5">Full Name</p>
              <input
                name="full_name"
                value={reviewState.full_name}
                onChange={handleChange}
                placeholder="Enter your name"
              />
              <p className="mv0 pb10px pt6px fw5">Product review</p>
              <textarea
                name="review"
                value={reviewState.review}
                onChange={handleChange}
                placeholder="What did you like or dislike about the product?"
              />
            </div>
            <button
              disabled={disableSubmitBtn()}
              onClick={handleSubmit}
              style={{
                opacity: disableSubmitBtn() ? 0.21 : 1,
                backgroundColor: storeInfo?.theme?.colors?.primary_color
                  ? storeInfo?.theme?.colors?.primary_color
                  : '#996fd5',
              }}
            >
              Submit
            </button>
          </section>
        </div>
      </Modal>
    );

  return (
    <Modal
      visible={open}
      height="auto"
      customStyles={{
        height: '439px',
        borderRadius: '12px',
        width: '528px',
      }}
      className="bottom-modal"
      onClose={onClose}
    >
      <div className={styles.modal}>
        <img
          onClick={onClose}
          className={styles.crossIcon}
          src="/assets/images/filled-close-icon.png"
          height="18px"
          width="18px"
        />
        <div className="flex items-center mb28px">
          <img
            src={getImageUrlFromCompressedImages(compressedImages, imageUrl, {
              maxImgSize: imgSizeEnums.LOW,
            })}
            width="64px"
            height="64px"
          />
          <div className="flex flex-column pl16px">
            <p className="mv0 f14px fw4 pb10px">{product?.name || product?.item_name}</p>
            <div className="flex items-center">
              <ReactStars
                key={`stars_${reviewState.rating}`}
                size={24}
                classNames={styles.gap}
                onChange={handleRatingChange}
                value={reviewState.rating}
                count={5}
              />
              {loggedInUserReview?.rating && !loggedInUserReview?.review ? (
                <p className="ml12px mv0 bg-lime-green-color pv4px ph8px f12px fw5 white-color br2 ">
                  Submitted
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex flex-column">
          <p className="mv0 pb10px 333333DE fw5 f14px">Full Name</p>
          <input
            name="full_name"
            value={reviewState.full_name}
            onChange={handleChange}
            placeholder="Enter your name"
          />
        </div>
        <div className="flex flex-column pt24px">
          <p className="mv0 pb10px 333333DE fw5 f14px">Product review</p>
          <textarea
            name="review"
            value={reviewState.review}
            onChange={handleChange}
            placeholder="What did you like or dislike about the product?"
          />
        </div>
        <div className={styles.btnContainer}>
          <button
            disabled={disableSubmitBtn()}
            className={styles.submitBtn}
            onClick={handleSubmit}
            style={{
              opacity: disableSubmitBtn() ? 0.21 : 1,
              backgroundColor: storeInfo?.theme?.colors?.primary_color
                ? storeInfo?.theme?.colors?.primary_color
                : '#996fd5',
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomerReviewModal;
